<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.listadoespecialidades') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <especialidades-estados />
                </div>
                <div class="col-md-10">
                    <tabla-datos 
                    :columnas="columnas" 
                    :datos="especialidades"
                    :filtros="filtros" 
                    :filtrosBuscador="filtrosBuscador" 
                    :total="total"
                    @quieroDatos="obtenerEspecialidades" 
                ></tabla-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from './../../../services/PwgsApi';
import EspecialidadesEstados from './listado/EspecialidadesEstados.vue'
import { defineAsyncComponent } from 'vue';
//import BotonesAcciones from './../../BotonesAcciones.vue'
import { FilterMatchMode } from 'primevue/api';
import TablaDatos from '../../TablaDatos.vue';
export default {
    name: "especialidades",
    components: {
        'especialidades-estados': EspecialidadesEstados,
        'tabla-datos': TablaDatos,
     //   'botones-acciones': BotonesAcciones,
    },
    data() {
        return {
            nombre:'',
            loading1: true,
            modificado: false,
            especialidades: [],
            filtrosBuscador: ['nombre'],
            displayConfirmation: false,
            filters2: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'nombre': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            parametros: null, // query params de DataTable
            filtros: { // filtros de columna
                'nombre': { value: '', matchMode: 'contains' },
            },
            columnas:[
                {
                    header: 'Especialidad',
                    field: 'nombre',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,     
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idespecialidad',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar especialidad ' + id);
                                this.$router.push({ name: 'Especialidad', params: { id: id }});
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'idespecialidad',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar especialidad ' + id );                               
                                if (confirm('Desea eliminar especialidad ' + id )) {                                   
                                    this.eliminarEspecialidad(id);
                                }
                            }
                        },
                    ]
                }
                    
            ]

        }
    },
    methods: {
        async obtenerEspecialidades(parametros) {
            /* get  modulos/pwgsapi/index.php/especialidades */
            const api = new PwgsApi();
            const params = parametros;
            const especialidades = await api.get('especialidades', params);
            this.nombre = '';   
            this.especialidades = especialidades.datos;
            this.total = especialidades.n_total_registros;
            this.modificado = false; 
            this.columnas[0].header = this.$t("general.especialidad");
            this.columnas[1].header = this.$t("general.acciones");
        },
        esColumnaPersonalizada(col) {
            return col.formato || col.acciones || col.componente;
        },
        componenteDinamico(ruta) {
            console.log('importando dinÃ¡micamente componente ' + ruta);
            return defineAsyncComponent(() => import(`@/${ruta}`));
        },
        async eliminarEspecialidad(id) {
            /* delete  modulos/pwgsapi/index.php/especialidades/:id */
            const api = new PwgsApi;
            await api.delete('especialidades/' + id);
            this.modificado = true;          
        },
        crear() {

            this.$router.push({ name: 'Crear Especialidades', params: { id: 0 } });

        },
        refrescar() {
            this.obtenerEspecialidades();

        },
        async crearnuevo() {
            /* post  modulos/pwgsapi/index.php/especialidades */
            const api = new PwgsApi;
            const subidadatos = { nombre: this.nombre };
            const numero = await api.post('especialidades', subidadatos);
            const param = numero.id;
            this.displayConfirmation = false; 
            this.obtenerEspecialidades();
            this.$router.push({ name: 'Especialidad', params: { id: param } });
        },
    },
    watch:
    {
        modificado() {
            this.obtenerEspecialidades();
        }
    },
    mounted() {
        this.obtenerEspecialidades();
        this.loading1 = false;
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>